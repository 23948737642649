
import { createRef, useEffect, useRef } from "react";
import { Header, Icon, Input } from "semantic-ui-react";
import { useStore } from "../../stores/store";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function BlogSearchWidget() {
    const [searchParam] = useSearchParams();
    const searchRef = useRef<any>();
    const navigate = useNavigate();

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            navigate(`/blog/search?value=${searchRef.current.value}`);
        }
    }

    useEffect(() => {
        if (searchParam.get('value'))
            searchRef.current.value = searchParam.get('value');
    }, [])

    return (
        <>
            <Header as='h4' content='SEARCH' />
            <div className="search-wrap">
                <input
                    className="search-input"
                    ref={searchRef}
                    onKeyDown={handleKeyDown}
                />
                <Icon
                    className="search-icon"
                    onClick={() => { navigate(`/blog/search?value=${searchRef.current.value}`) }}
                    name='search' inverted circular link
                />
            </div>
        </>
    )
}