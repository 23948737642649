import React, { useEffect } from "react";
import {
  Container,
  Divider,
  Grid,
  Header,
  List,
  Segment,
} from "semantic-ui-react";
import { useStore } from "../../stores/store";
import LoadingComponent from "../LoadingComponent";
import { observer } from "mobx-react-lite";

export default observer(function Footer() {
  const {
    aboutUsStore: { loadAboutUs, aboutUs },
  } = useStore();

  useEffect(() => {
    if (aboutUs.body === "") {
      loadAboutUs();
    }
  }, [aboutUs]);

  return (
    <Segment
      style={{ padding: "5em 0em", margin: "0" }}
      inverted
      textAlign="left"
      className="footer-container"
    >
      <Container>
        <Grid stackable inverted columns={3}>
          <Grid.Row>
            <Grid.Column textAlign="center" width={10}>
              <Header color="grey" as="h3">
                {aboutUs.header}
              </Header>
              <span style={{ fontSize: "10px" }}>{aboutUs.body}</span>
              <img
                src="/assets/logo.png"
                width="60px"
                height="60px"
                alt=""
                style={{ margin: "10px auto" }}
              />
            </Grid.Column>
            <Grid.Column textAlign="center">
              <Header color="grey" as="h3">
                COMPANY
              </Header>
              <List>
                <List.Item>
                  <a href="/">Homepage</a>
                </List.Item>
                <List.Item>
                  <a href="/booking">Booking</a>
                </List.Item>
                <List.Item>
                  <a href="/gallery">Gallery</a>
                </List.Item>
                <List.Item>
                  <a href="/reviews">Reviews</a>
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
      <Container className="end-footer-container" textAlign="center">
        <Divider className="footer-divider" clearing={true} />
        <p>Rain City Detail © 2023</p>
      </Container>
    </Segment>
  );
});
