import { useState } from "react";
import { Button, Card, Grid, Header, Icon, Image } from "semantic-ui-react";
import { Service } from "../../models/service";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/store";
import ServiceCard from "../../common/components/ServiceCard";

interface Props {
  services: Service[];
  predicate: string;
  setService: (id: string, price: string) => void;
  selectedService?: string;
}

export default observer(function ServiceUser({
  services,
  predicate,
  selectedService,
  setService,
}: Props) {
  const {
    serviceStore: { loading, deleteService },
  } = useStore();
  const [target, setTarget] = useState("");

  return (
    <>
      {services.filter((s) => s.serviceType === "exterior").length > 0 && (
        <Grid.Column width={16}>
          <Header
            style={{ padding: "15px" }}
            as="h2"
            textAlign="center"
            content="Exterior Detail Packages"
          />
        </Grid.Column>
      )}
      {services
        .filter((s) => s.serviceType === "exterior")
        .map((service) => (
          <Grid.Column
            textAlign="center"
            mobile={16}
            tablet={5}
            computer={4}
            key={service.id}
          >
            <ServiceCard
              service={service}
              predicate={predicate}
              setService={setService}
              selectedService={selectedService}
              target={target}
              setTarget={setTarget}
            />
          </Grid.Column>
        ))}
      {services.filter((s) => s.serviceType === "interior").length > 0 && (
        <Grid.Column width={16}>
          <Header
            style={{ padding: "15px" }}
            as="h2"
            textAlign="center"
            content="Interior Detail Packages"
          />
        </Grid.Column>
      )}
      {services
        .filter((s) => s.serviceType === "interior")
        .map((service) => (
          <Grid.Column
            textAlign="center"
            mobile={16}
            tablet={5}
            computer={4}
            key={service.id}
          >
            <ServiceCard
              service={service}
              predicate={predicate}
              setService={setService}
              selectedService={selectedService}
              target={target}
              setTarget={setTarget}
            />
          </Grid.Column>
        ))}
    </>
  );
});
