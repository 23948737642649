import { Menu, Segment } from "semantic-ui-react";

export default function NavbarLg({ renderLinks }: any) {
  return (
    <Segment inverted attached size="mini" style={{ padding: "0" }}>
      <Menu style={{ opacity: "100%" }} inverted secondary>
        {renderLinks()}
      </Menu>
    </Segment>
  );
}
