import { Container, Grid, Header, Segment } from "semantic-ui-react";
import CoverSlogan from "../cover slogans/CoverSlogan";
import BlogItemPlaceHolder from "./BlogItemPlaceHolder";
import BlogItems from "./BlogItems";
import Category from "./Category";
import LatestPost from "./LatestPost";
import { useStore } from "../../stores/store";
import BlogSearchWidget from "./BlogSearchWidget";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { observer } from "mobx-react-lite";

export default observer(function BlogSearch() {
    const { blogStore: { loadBlogsSearch, blogs, loadBlogs,
        categoryBlogCounts, latestBlog, blogsSearch, blogLoadingInitial, blogSearchLoading },
        categoryStore: { categories, loadCategories } } = useStore();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (blogs.length < 1) loadBlogs();
        if (categories.length < 1) loadCategories();
    }, [])

    useEffect(() => {
        if (searchParams.get('value') && searchParams.get('value')!.length > 0) {
            loadBlogsSearch(searchParams.get('value')!)
        }

    }, [searchParams])

    return (
        <>
            <CoverSlogan slogan={'SEARCH RESULT'}
                image={'/assets/sliderImages/Detail6.jpg'} />
            <Segment style={{ padding: '8em 0 0 0' }} basic clearing>
                <Container>
                    <Grid>
                        <Grid.Column computer={11} tablet={9} mobile={16}>
                            {
                                blogSearchLoading ?
                                    <>
                                        <BlogItemPlaceHolder />
                                        <BlogItemPlaceHolder />
                                        <BlogItemPlaceHolder />
                                    </>
                                    :
                                    (
                                        blogsSearch.length === 0 ?
                                            <Header as='h4'>Can't find any post with '{searchParams.get('value')}'</Header> :
                                            blogsSearch.map(blog => (
                                                <BlogItems key={blog.id} blog={blog} />
                                            ))
                                    )
                            }
                        </Grid.Column>
                        <Grid.Column computer={5} tablet={7} mobile={16}>
                            <div style={{ padding: '2em 0 0 1em' }}>
                                <BlogSearchWidget />
                            </div>
                            <div style={{ padding: '2em 0 0 1em' }}>
                                <LatestPost blogs={latestBlog()} />
                            </div>
                            <div style={{ padding: '2em 0 0 1em' }}>
                                <Category categoryBlogCounts={categoryBlogCounts} />
                            </div>
                        </Grid.Column>
                    </Grid>
                </Container>
            </Segment>
        </>
    )
})