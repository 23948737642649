import { Service } from "./service";

export interface AddOn {
  id: string;
  name: string;
  price: string;
  pricePlus: boolean;
  description: string;
  order: string;
  service: Service;
}

export class AddOn implements AddOn {
  constructor(addOn?: AddOnFormValues) {
    if (addOn) {
      Object.assign(this, addOn);
      this.service = new Service();
      this.service.id = addOn.serviceId;
      this.service.price = "0";
      this.service.order = "0";
    }
  }
}

export class AddOnFormValues {
  id = "";
  name = "";
  price = "0";
  pricePlus = false;
  description = "";
  serviceId = "";
  serviceName = "";
  vehicleType = "";
  order = "0";
  constructor(addOn?: AddOnFormValues) {
    if (addOn) {
      this.id = addOn.id;
      this.name = addOn.name;
      this.price = addOn.price;
      this.pricePlus = addOn.pricePlus;
      this.description = addOn.description;
      this.serviceId = addOn.serviceId;
      this.serviceName = addOn.serviceName;
      this.vehicleType = addOn.vehicleType;
      this.order = addOn.order;
    }
  }
}

export const pricePlus = [
  { text: "Exactly", value: false },
  { text: "Starting from", value: true },
];

// export class BookingAddOn {
//     id = "";
//     name = "";
//     description = "";
//     price = "0";
//     constructor(addOn?: BookingAddOn) {
//         if (addOn) {
//             this.id = addOn.id;
//             this.name = addOn.name;
//             this.price = addOn.price;
//             this.description = addOn.description;
//         }
//     }
// }
