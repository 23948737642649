import { useEffect, useState } from "react";
import NavBar from "../../layout/NavBar";
import Slider from "../sliders/Slider";
import ContactInfo from "../info/ContactInfo";
import MapReveal from "../info/MapReveal";
import { Container, Divider, Grid, Header, Segment } from "semantic-ui-react";
import Footer from "../../layout/footer/Footer";
import AboutUsHome from "../about-us/AboutUsHome";
import VehicleType from "../bookings/VehicleType";
import ServiceUser from "../bookings/ServiceUser";
import { Booking } from "../../models/booking";
import { Service, vehicleTypeOptions } from "../../models/service";
import { useStore } from "../../stores/store";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../../layout/LoadingComponent";
import Process from "../about-us/Process";

export default observer(function HomePage() {
  const {
    serviceStore: {
      servicesByVehicleType,
      services,
      loadServices,
      loadingInitial,
    },
    bookingStore: { selectedBooking, setSelectedBooking },
    carouselStore: { carousels, loadCarousels, prepareSlider },
  } = useStore();
  const [booking, setBooking] = useState<Booking>(new Booking());
  const navigate = useNavigate();

  useEffect(() => {
    if (services.length < 1) loadServices();
    if (selectedBooking) {
      setBooking(selectedBooking);
    } else {
      setVehicleType(vehicleTypeOptions[0].value);
    }
  }, []);

  useEffect(() => {
    if (carousels.length < 1) loadCarousels();
    // setSrcs(prepareSlider(carousels))
  }, []);

  const setVehicleType = (vehicleType: string) => {
    setBooking((current) => {
      if (current.service.vehicleType === vehicleType) {
        return new Booking({
          ...current,
          total: "0",
          bookingAddOns: [],
          service: new Service(),
        });
      } else {
        return new Booking({
          ...current,
          total: "0",
          bookingAddOns: [],
          service: new Service({
            ...current.service,
            id: "",
            vehicleType: vehicleType,
          }),
        });
      }
    });
  };

  const setService = (id: string, price: string) => {
    const newBooking = new Booking({
      ...booking,
      total: parseInt(price) >= 9999 ? "Ask for quote" : price + " $",
      bookingAddOns: [],
      service: new Service({ ...booking.service, id: id, price: price }),
    });
    setSelectedBooking(newBooking);
    navigate("/booking");
  };

  if (loadingInitial) return <LoadingComponent content="Loading page..." />;

  return (
    <>
      <NavBar predicate="user" />

      <Slider predicate="carousel" srcs={prepareSlider(carousels)} />

      <Segment
        className="package-introducing"
        basic
        style={{ padding: "8em 0em 0em 0em" }}
      >
        <Container text textAlign="center">
          <Header as="h1">RAIN CITY DETAIL</Header>
          <Divider className="packages-divider" />
          <span>Detailing service</span>
        </Container>
      </Segment>

      <AboutUsHome />

      <Process />

      <Segment
        className="package-introducing"
        basic
        style={{ padding: "4em 0em 5em 0em" }}
      >
        <Container text textAlign="center">
          <Header as="h1">SERVICE PACKAGES</Header>
          <Divider className="packages-divider" />
          <span>Show your car some love</span>
        </Container>
      </Segment>

      <VehicleType
        vehicleType={booking.service.vehicleType}
        setVehicleType={setVehicleType}
        style={{ padding: "0" }}
      />

      <Segment basic style={{ padding: "5em 0 0 0" }}>
        <Grid container>
          <ServiceUser
            selectedService={booking.service.id}
            setService={setService}
            services={servicesByVehicleType(booking.service.vehicleType)}
            predicate={"user"}
          />
        </Grid>
      </Segment>

      <ContactInfo />
      <MapReveal />
      <Footer />
    </>
  );
});
