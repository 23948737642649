import { AddOnFormValues } from "./addOn";

export class Service {
  id = "";
  vehicleType = "";
  serviceType = "";
  name = "";
  price = "0";
  priceDecimal = "0";
  description = "";
  imageId = "";
  image = "";
  order = "0";
  constructor(service?: Service) {
    if (service) {
      // if (service.price) {
      //     if (service.price.indexOf('.') !== -1) {
      //         this.price = service.price.split('.')[0];
      //         this.priceDecimal = service.price.split('.')[1];
      //     } else {
      //         this.price = service.price;
      //     }
      // }

      this.id = service.id;
      this.vehicleType = service.vehicleType;
      this.serviceType = service.serviceType;
      this.name = service.name;
      this.price = service.price ? service.price : "0";
      this.priceDecimal = service.priceDecimal ? service.priceDecimal : "0";
      this.description = service.description;
      this.imageId = service.imageId;
      this.image = service.image;
      this.order = service.order;
    }
  }
}

export class ServiceFormValues {
  id = "";
  vehicleType = "";
  serviceType = "";
  name = "";
  price = "0";
  priceDecimal = "0";
  description = "";
  imageId = "";
  image = "";
  order = "0";
  addOns: AddOnFormValues[] = [];
  constructor(service?: ServiceFormValues) {
    if (service) {
      // if (service.price) {
      //     if (service.price.indexOf('.') !== -1) {
      //         this.price = service.price.split('.')[0];
      //         this.priceDecimal = service.price.split('.')[1];
      //     } else {
      //         this.price = service.price;
      //     }
      // }

      this.id = service.id;
      this.vehicleType = service.vehicleType;
      this.serviceType = service.serviceType;
      this.name = service.name;
      this.price = service.price ? service.price : "0";
      this.priceDecimal = service.priceDecimal ? service.priceDecimal : "0";
      this.description = service.description;
      this.imageId = service.imageId;
      this.image = service.image;
      this.order = service.order;
      this.addOns = service.addOns;
    }
  }
}

export class ServiceOptions {
  text = "";
  value = "";
  constructor(service?: Service) {
    if (service) {
      this.text = service.vehicleType + " - " + service?.name;
      this.value = service?.id;
    }
  }
}

export const serviceType = [
  {
    text: "Exterior",
    value: "exterior",
  },
  {
    text: "Interior",
    value: "interior",
  },
];

export const vehicleTypeOptions = [
  {
    text: "Extra Small",
    des: "Two-Seater Cars, Single Cabs",
    value: "extra small",
    icons: "b",
  },
  {
    text: "Small",
    des: "Standard Coupes and Sedans, Small Sports Cars",
    value: "small",
    icons: "c",
  },
  {
    text: "Medium",
    des: "Full-Size Sedans, Wagons, Crossovers,Compact SUVS",
    value: "medium",
    icons: "e",
  },
  {
    text: "Large",
    des: "Full-Size SUVS, Small Trucks",
    value: "large",
    icons: "j",
  },
  {
    text: "Extra Large",
    des: "Extended SUVS, Large Trucks, Vans",
    value: "extra large",
    icons: "g",
  },
  {
    text: "Other",
    des: "Cargo Vans, Box Trucks, Sprinter Vans, Bus, Trailers, Motorhomes",
    value: "other",
    icons: "k",
  },
];
