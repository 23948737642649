import { Fragment, useState } from "react";
import { Container, Icon, Segment, TransitionGroup } from "semantic-ui-react";

export default function MapReveal() {
  const [isMapReveal, setIsMapReveal] = useState(false);

  return (
    <div
      style={isMapReveal ? { height: "490px" } : { height: "90px" }}
      className="map-wrapper"
    >
      <Segment
        basic
        style={{ padding: "0em", margin: "0" }}
        className="map-reveal-container"
      >
        <div
          onClick={() => {
            setIsMapReveal(!isMapReveal);
          }}
          className="toggle-map-button"
        >
          <Icon name="map" color="grey" size="big" />
          <p>{isMapReveal ? "HIDE MAP" : "SHOW MAP"}</p>
        </div>
      </Segment>
      <TransitionGroup as={Container} duration={200} animation="slide up" fluid>
        <Container className="map-container" fluid>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2692.284507979577!2d-122.28808490000002!3d47.56225260000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54906a72cd072251%3A0x27cf326091152268!2s4517%20Rainier%20Ave%20S%2C%20Seattle%2C%20WA%2098118%2C%20USA!5e0!3m2!1sen!2s!4v1696591838434!5m2!1sen!2s"
            width="100%"
            height="400"
            style={{ border: 0 }}
            allowFullScreen={false}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </Container>
      </TransitionGroup>
    </div>
  );
}
