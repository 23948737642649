import { Grid, Header, Icon, Segment } from "semantic-ui-react";

export default function ContactInfo() {
  return (
    <Segment
      clearing
      basic
      className="contact-info-container"
      style={{ padding: "8em 0em" }}
    >
      <Grid
        verticalAlign="middle"
        container
        textAlign="center"
        stackable
        doubling
        columns={3}
      >
        <Grid.Column>
          <Grid>
            <Grid.Column width={6} textAlign="right">
              <Icon name="phone" size="huge" color="blue" />
            </Grid.Column>
            <Grid.Column width={10} textAlign="left">
              <Header as="h3">Call US AT</Header>
              <span>
                <strong>(206) 466-8544</strong>
              </span>
            </Grid.Column>
          </Grid>
        </Grid.Column>
        <Grid.Column>
          <Grid>
            <Grid.Column width={6} textAlign="right">
              <Icon name="map marker alternate" size="huge" color="blue" />
            </Grid.Column>
            <Grid.Column width={10} textAlign="left">
              <Header as="h3">OUR ADDRESS</Header>
              <span>4517 Rainier Ave S.</span>
              <span>Seattle, WA 98118</span>
            </Grid.Column>
          </Grid>
        </Grid.Column>
        <Grid.Column>
          <Grid>
            <Grid.Column width={6} textAlign="right">
              <Icon name="clock" size="huge" color="blue" />
            </Grid.Column>
            <Grid.Column width={10} textAlign="left">
              <Header as="h3">WORKING HOURS</Header>
              <span>10:00 AM – 7:00 PM</span>
            </Grid.Column>
          </Grid>
        </Grid.Column>
      </Grid>
    </Segment>
  );
}
